import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ImageHeaderContainer from "../components/Generic-Components/ImageHeaderContainer";
import bigImage from "../assets/images/bussiness-people-working-in-team-in-an-office.jpg";
import smallImage from "../assets/images/handshake.jpg";
import JobPositionsTable from "../components/Careers/JobPositionsTable";
import { position } from "../components/Careers/example-job-data-description";
import JobDescription from "../components/Careers/JobDescription";
import OurValues from "../components/Careers/OurValues";
import OurPerks from "../components/Careers/OurPerks";
import Contact from "../components/Careers/Contact";
import {
  Container,
  BigParagraph,
} from "../components/Generic-Components/GenericStyledComponents";

const Careers = () => {
  const [showPosition, setShowPosition] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const contentHeader = {
    title: ["Join", { span: "OneX" }],
    paragraphs: [
      {
        line: [
          "Do you want to solve human challenges using technology? Are you driven by innovation? Are you a problem solver?",
        ],
      },
    ],
    background: [bigImage, smallImage],
  };

  return (
    <>
      <ImageHeaderContainer
        background={contentHeader.background}
        title={contentHeader.title}
        paragraphs={contentHeader.paragraphs}
        filter
        smallText
      />
      <Container>
        <FlexContainer>
          <TitleContainer>
            <Title>We're always looking for authentic,</Title>
            <Title> talented people to work with</Title>
          </TitleContainer>
          <BigParagraph>
            We’re a small, distributed team which allows us to move quickly and
            break things… and sometimes put things together. We like working on
            big problems that are hard to solve, yet incredibly rewarding.
          </BigParagraph>
        </FlexContainer>
      </Container>
      <JobPositionsTable
        setShowPosition={setShowPosition}
        showPosition={showPosition}
      />
      <OurPerks />
      <OurValues />
      <Contact />
    </>
  );
};

export default Careers;

const FlexContainer = styled.div`
  display: flex;
  padding: 10rem 0 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  text-align: center;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.h2`
    width: 70%;
    font-size: 4.5rem;
    font-weight bold;
    text-transform: capitalize;
    @media (min-width:768px){
    font-size: 3.7rem;
    }
    &.secondary{
    font-size: 4.2rem;
    @media (min-width:768px){
    font-size: 3.4rem;
    }
    }
    
`;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  loaddingPositions: false,
  positions: [],
  error: "",
  loaddingPosition: false,
  position: {},
};

export const fetchPositions = createAsyncThunk(
  "positions/fetchPositions",
  () => {
    return axios
      .get("http://localhost:4000/positions/open")
      .then((response) => response.data);
  }
);

export const fetchPositionById = createAsyncThunk(
  "positions/fetchPositionById",
  (id) => {
    return axios
      .get(`http://localhost:4000/positions/${id}`)
      .then((response) => response.data);
  }
);
const positionsSlice = createSlice({
  name: "positions",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPositions.pending, (state) => {
      state.loaddingPositions = true;
    });
    builder.addCase(fetchPositions.fulfilled, (state, action) => {
      state.loaddingPositions = false;
      state.positions = action.payload;
      state.error = "";
    });
    builder.addCase(fetchPositions.rejected, (state, action) => {
      state.loaddingPositions = false;
      state.positions = [];
      state.error = action.error.message;
    });
    builder.addCase(fetchPositionById.pending, (state) => {
      state.loaddingPositions = true;
    });
    builder.addCase(fetchPositionById.fulfilled, (state, action) => {
      state.loaddingPosition = false;
      state.position = action.payload;
      state.error = "";
    });
    builder.addCase(fetchPositionById.rejected, (state, action) => {
      state.loaddingPosition = false;
      state.position = {};
      state.error = action.error.message;
    });
  },
});

export default positionsSlice.reducer;

export const contentEnglish = {
  title: "PRIVACY POLICY",
  introduction: [
    "Your privacy is important to us, so Onex Servicios Digitales, S.A. de C.V, has created the following Privacy Policy (“Policy”) to let you know what information we collect when you visit our Site https://onexdigitalservices.com/ (“Site”), why we collect it and how we use it.",
    "The terms “You,” “Your,” “Yours” and “User” refer to the entity/person/organization using our Site.",
    "When this Policy mentions “We”, “Us,” and “Our” it refers to Onex Servicios Digitales, S.A. de C.V and its subsidiaries and affiliates.",
    "This Privacy Policy is governed by our Terms of Services.",
    "For any questions regarding this Policy or any requests regarding the processing of personal data, please contact us at contact@onexdigitalservices.com.",
  ],
  sections: [
    {
      subtitle: "1- INFORMATION WE COLLECT FROM YOU",
      content: [
        {
          paragraphs: [
            "We collect the information You provide to us and this information is necessary for the adequate performance of the contractual arrangement which is in place between You and us and allow us to comply with our legal obligations.",
          ],
        },
        {
          list: [
            "Contact Information. We ask You to provide the contact information, such as Email, Name, Surname, Personal Number, Phone, Address.",
            "Communications, Chats, Messaging. When you communicate with us through email or any other way, we collect information about your communication and any information You choose to provide or disclose. In order to answer your request, we may access information provided by email, chats, purchase history, etc.",
          ],
        },
      ],
    },
    {
      subtitle: "2- INFORMATION WE COLLECT AUTOMATICALLY",
      content: [
        {
          paragraphs: [
            "When you use our Site or contact us directly we may collect information, including your personal information, about the way you act in our Site, the services You use and how You use them.",
            "This information is necessary for the adequate performance of the contract between You and us, to enable us to comply with legal obligations and given our legitimate interest in being able to provide and improve the functionalities of the Site.",
          ],
        },
        {
          list: [
            "Geo-location data. We collect information about your approximate location as determined by data such as your IP address to offer you an improved user experience. Such data may be collected only when you access the Site using your device.",
            "Publicly available personal Information.",
          ],
        },
      ],
    },
    {
      subtitle: "3- THE WAY WE USE YOUR INFORMATION",
      content: [
        {
          paragraphs: [
            "We process your information adhering to the general data processing principles.",
            "We may use the information we collect through our Site for a number of reasons, including to:",
          ],
        },
        {
          list: [
            "to identify user",
            "to create trusted environment",
            "to stay connected",
            "to contact user",
            "to improve services",
            "to ensure data security and prevent fraud",
            "to request feedback",
            "to post testimonials",
            "to provide support",
          ],
        },
        {
          paragraphs: [
            "We will normally collect personal information from you only where we have your consent to do so, where we need the personal information to perform a contract with you, or where the processing is in our legitimate business interests.",
          ],
        },
      ],
    },
    {
      subtitle: "5- SENSITIVE INFORMATION",
      content: [
        {
          paragraphs: [
            "We do not collect sensitive information such as political opinions, religious or philosophical beliefs, racial or ethnic origin, genetic data, biometric data, health data or data related a sexual orientation.",
            "Please do not send, upload, or provide us any sensitive data and contact us using the contact details below if you believe that we might have such information. We have a right to delete any information we believe it might contain sensitive data.",
          ],
        },
      ],
    },
    {
      subtitle: "6- THIRD PARTY LINKS",
      content: [
        {
          paragraphs: [
            "Our Site may have links to other websites. Please review their privacy policies to learn more about how they collect and use your personal data, because we do not control their policies and personal data processing practices.",
          ],
        },
      ],
    },
    {
      subtitle: "7- RETENTION",
      content: [
        {
          paragraphs: [
            "We retain your personal information to provide services to you and as otherwise necessary to comply with our legal obligation, resolve disputes, and enforce our agreements.",
            "We will retain your personal information as long as we need it to provide services to you, unless we are otherwise required by law or regulations to retain your personal information longer.",
          ],
        },
      ],
    },
    {
      subtitle: "8- SECURITY",
      content: [
        {
          paragraphs: [
            "We have implemented security measures designed to protect the personal information you share with us, including physical, electronic and procedural measures. Among other things, we regularly monitor our systems for possible vulnerabilities and attacks.",
            "Regardless of the measures and efforts taken by us, the transmission of information via internet, email or text message is not completely secure. We do not guarantee the absolute protection and security of your personal information or any other User Content you upload, publish or otherwise share with us or anyone else.",
            "We therefore encourage you to avoid providing us or anyone with any sensitive information of which you believe its disclosure could cause you substantial or irreparable harm.",
            "If you have any questions regarding the security of our Site or Services, you are welcome to contact us at contact@onexdigitalservices.com.",
          ],
        },
      ],
    },
    {
      subtitle: "9- YOUR RIGHTS",
      content: [
        {
          paragraphs: [
            "You are entitled to a range of rights regarding the protection of your personal information. Those rights are:",
            "The right to access the information we have about you. If you wish to access your personal information that we collect, you can do so at any time by contacting us using the contact details provided below.",
            "The right to rectify inaccurate information about you. You can correct, update or request deletion of your personal information by contacting us using the contact details provided below.",
            "The right to object the processing. When we rely on your consent to process your personal information, you may withdraw consent at any time by contacting us using the contact details provided below. This will not affect the lawfulness of processing prior to the withdrawal of your consent.",
            "The right to lodge a complaint. You can raise questions or complaints to the national Data Protection Agency in your country of residence in the event where your rights may have been infringed. However, we recommend attempting to reach a peaceful resolution of the possible dispute by contacting us first.",
            "The right to erase any data concerning you. You may demand erasure of data without undue delay for legitimate reasons, e.g. where data is no longer necessary for the purposes it was collected, or where the data has been unlawfully processed.",
          ],
        },
      ],
    },
    {
      subtitle: "10- APPLICATION OF POLICY",
      content: [
        {
          paragraphs: [
            "Our Policy does not apply to services offered by other companies or individuals, including products or sites that may be displayed to you in search results, sites that may include our services or other sites linked from our Site or Services.",
          ],
        },
      ],
    },
    {
      subtitle: "11- AMENDMENTS",
      content: [
        {
          paragraphs: [
            "Our Policy may change from time to time. We will post any Policy changes on our Site and, if the changes are significant, we may consider providing a more explicit notice (including, for certain services, email notification of Policy changes).",
          ],
        },
      ],
    },
    {
      subtitle: "12- ACCEPTANCE OF THIS POLICY",
      content: [
        {
          paragraphs: [
            "We assume that all Users of this Site have carefully read this document and agree to its contents. If someone does not agree with this Policy, they should refrain from using our Site. We reserve the right to change our Policy at any time and inform by using the way as indicated in Section 12. Continued use of this Site implies acceptance of the revised Policy.",
          ],
        },
      ],
    },
    {
      subtitle: "13- FURTHER INFORMATION",
      content: [
        {
          paragraphs: [
            "If you have any further questions regarding the data we collect, or how we use it, then please feel free to contact us at the details as indicated above.",
          ],
        },
      ],
    },
  ],
};

import React from 'react'
import styled from 'styled-components'

const JobDescription = ({position, requirements, summary, years, ubication, id, setShowPosition}) => {
  return (
    <BigContainer>
      <Title onClick={()=>setShowPosition(false)} className='back-button'>All Positions</Title>
      <SmallContainer>
      <Title>{position}</Title>
      <Subtitle>ID:{id}|{years}|{ubication}</Subtitle>
      </SmallContainer>
      <SmallContainer>
      <Subtitle>Job Summary:</Subtitle>
      <Paragraph>{summary}</Paragraph>
      </SmallContainer>
      <SmallContainer>

      <Subtitle className='bolder'>Basic Understanding of:</Subtitle>
      <UlElement>
      {requirements.basic.map((elem,ind)=>(
        <LiElement key={ind}>{elem}</LiElement>
      ))}
      </UlElement>
      </SmallContainer>
      <SmallContainer>
      <Subtitle className='bolder'>Strong in:</Subtitle>
      <UlElement>
      {requirements.strong.map((elem,ind)=>(
        <LiElement key={ind}>{elem}</LiElement>
      ))}
      </UlElement>
      </SmallContainer>
      <SmallContainer>
      <Subtitle className='bolder'>Good to have:</Subtitle>
      <UlElement>
      {requirements.plus.map((elem,ind)=>(
        <LiElement key={ind}>{elem}</LiElement>
      ))}
      </UlElement>
      </SmallContainer>
    </BigContainer>
  )
}

export default JobDescription

const BigContainer = styled.div`
  gap :3rem ;
  display: flex;
  flex-direction: column;
  padding: 10rem 0 15rem 0;
`
const SmallContainer = styled.div`
    
`

const Title = styled.h2`
    font-size: 2rem;
    font-weight: bold;
    &.back-button{
      color:rgba(9, 186, 239, 0.7);
      &:hover{
        cursor: pointer;
      }
    }
`

const Subtitle = styled.h3`
    font-size: 1.5rem;
    &.bolder{
        font-weight: bold;
    }
`

const UlElement = styled.ul`
    font-size: 1.5rem;
`

const LiElement = styled.li`
`

const Paragraph = styled.p`
    font-size: 1.5rem;
`
import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import DOMPurify from "dompurify";

const ModalPositions = ({ rawHtml, setShowPosition, showPosition, id }) => {
  const contentRef = useRef(null);
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };
  const [width, setWidth] = useState(0);
  useEffect(() => {
    if (contentRef.current) setWidth(contentRef.current.offsetWidth);
  }, [rawHtml]);

  return (
    <>
      <ModalElement className={showPosition ? "" : "hidden"}>
        <CloseButton onClick={() => setShowPosition(false)}>
          &times;
        </CloseButton>
        <div
          ref={contentRef}
          className="preview"
          dangerouslySetInnerHTML={createMarkup(rawHtml)}
        ></div>
        <Container width={width}>
          <ColorLink to={`/${id}/apply`}>APPLY</ColorLink>
          <ColorHeader
            className="aquamarine"
            onClick={() => setShowPosition(false)}
          >
            CLOSE
          </ColorHeader>
        </Container>
      </ModalElement>
      <Overlay
        className={showPosition ? "" : "hidden"}
        onClick={() => setShowPosition(false)}
      ></Overlay>
    </>
  );
};

export default ModalPositions;

const ModalElement = styled.div`
  position: absolute;
  overflow-y: auto;
  height: 60vh;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  background-color: white;
  padding: 5rem 6rem 5rem 6rem;
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.hidden {
    display: none;
  }
  @media (min-width: 768px) {
    width: 70%;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 999;
  &.hidden {
    display: none;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1.2rem;
  right: 2rem;
  font-size: 3rem;
  color: #333;
  cursor: pointer;
  border: none;
  background: none;
`;

const ColorHeader = styled.span`
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  background-image: linear-gradient(
    90deg,
    rgba(9, 9, 121, 1) 0%,
    #3cbfbd 55%,
    rgba(0, 212, 255, 1) 100%
  );
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size 0.3s;
  &:hover,
  :focus {
    background-size: 100% 2px;
  }
  @media (min-width: 768px) {
    font-size: 2rem;
  }
  &.aquamarine {
    color: #3cbfbd;
  }
  &.blue {
    color: #27aae1;
  }
`;
const Container = styled.div`
  padding-top: 2.8rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: ${(props) => props.width + "px"};
`;

const ColorLink = styled(Link)`
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  background-image: linear-gradient(
    90deg,
    rgba(9, 9, 121, 1) 0%,
    #3cbfbd 55%,
    rgba(0, 212, 255, 1) 100%
  );
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size 0.3s;
  &:hover,
  :focus {
    background-size: 100% 2px;
  }
  @media (min-width: 768px) {
    font-size: 2rem;
  }
  &.aquamarine {
    color: #3cbfbd;
  }
  &.blue {
    color: #27aae1;
  }
`;

import React from "react";
import styled from "styled-components";
import { BsSearch } from "react-icons/bs";

const JobsSearch = ({ setFilteredArr, data, setPage }) => {
  const handleOnChange = ({ target }) => {
    const filtered = data.filter(
      (elem) =>
        elem.title.toLowerCase().includes(target.value.toLowerCase()) ||
        elem.id.toLowerCase().includes(target.value.toLowerCase()) ||
        elem.location.toLowerCase().includes(target.value.toLowerCase())
    );
    console.log({ filtered, data });
    setFilteredArr(!target.value ? data : filtered);
    setPage(1);
  };

  return (
    <FlexContainer>
      <Label>
        <IconContainer>
          <BsSearch />
        </IconContainer>
        <Input
          type="text"
          onChange={handleOnChange}
          placeholder="Search: position|location|id"
        />
      </Label>
    </FlexContainer>
  );
};

export default JobsSearch;

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 4rem;
  height: 5rem;
  font-size: 2.7rem;
  width: 100%;
  @media (min-width: 768px) {
    justify-content: flex-end;
    height: 4rem;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  @media (min-width: 768px) {
    width: auto;
  }
`;
const Input = styled.input`
  border: 1.5px solid #eeeeee;
  border-radius: 0 5px 5px 0;
  height: 100%;
  padding-left: 1rem;
  outline: none;
  width: 100%;
  &:focus {
    border: 1.5px solid rgba(9, 186, 239, 0.7);
    outline: none;
    border-radius: 0 5px 5px 0;
  }
  @media (min-width: 768px) {
    width: 500px;
  }
`;
const IconContainer = styled.span`
  padding: 0 4rem 0 4rem;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  border: 1.5px solid #eeeeee;
  border-radius: 0 0 0 20px;
`;

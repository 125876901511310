import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { contentEnglish } from "../components/Privacy-Policy/privacy-policy-constant";
import { Paragraph } from "../components/Generic-Components/GenericStyledComponents";

const PrivacyAndLegal = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 120) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Container>
      <NavbarSpace scroll={scroll} />
      <Content>
        <Title>{contentEnglish.title}</Title>
        {contentEnglish.introduction.map((elem, index) => (
          <Paragraph key={index}>{elem}</Paragraph>
        ))}
        {contentEnglish.sections.map((elem, index) => {
          return (
            <section key={index}>
              <Subtitle>{elem.subtitle}</Subtitle>
              {elem.content.map((elem, index) => {
                if (elem.list)
                  return (
                    <UL key={index}>
                      {elem.list.map((elem, index) => (
                        <LI key={index}>{elem}</LI>
                      ))}
                    </UL>
                  );
                if (elem.paragraphs)
                  return (
                    <React.Fragment>
                      {elem.paragraphs.map((elem, index) => (
                        <Paragraph key={index}>{elem}</Paragraph>
                      ))}
                    </React.Fragment>
                  );
              })}
            </section>
          );
        })}
      </Content>
    </Container>
  );
};

export default PrivacyAndLegal;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
`;
const NavbarSpace = styled.div`
  height: 20rem;
  background-color: ${(props) =>
    props.scroll ? "transparent" : "rgba(0, 0, 0, 0.7)"};
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;
const Content = styled.div`
  margin: auto;
  padding-bottom: 4rem;
  width: 88%;
  padding-top: 25rem;
  @media (min-width: 576px) {
    width: 540px;
  }
  @media (min-width: 768px) {
    width: 720px;
  }
  @media (min-width: 992px) {
    width: 960px;
  }
  @media (min-width: 1200px) {
    width: 1140px;
  }
  @media (min-width: 1400px) {
    width: 1320px;
  }
`;

const Title = styled.h2`
  color: #27aae1;
  font-size: 3.2rem;
`;
const Subtitle = styled.h3`
  font-size: 3rem;
  margin: 2rem 0;
  @media (min-width: 768px) {
    font-size: 2.2rem;
  }
`;

const UL = styled.ul`
  margin: 0 8px;
`;
const LI = styled.li`
  font-size: 3rem;
  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

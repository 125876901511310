import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  Background,
  Container,
} from "../components/Generic-Components/GenericStyledComponents";
import { fetchPositionById } from "../redux/positionsSlice";

const FormVacancyDeveloper = () => {
  const MAX_COUNT = 10;
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPositionById(id));
  }, [dispatch, id]);

  const position = useSelector((state) => state.positions.position);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 120) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  return (
    <>
      <NavbarSpace scroll={scroll} />
      <Container>
        <HeaderContainer>
          <Title>{position.title}</Title>
          <SubTitle>{position.location}</SubTitle>
        </HeaderContainer>
      </Container>
      <Background>
        <Container>
          <Content>
            <SubTitle className="in-form">Submit your application</SubTitle>
            <Form>
              <Input
                type="file"
                id="uploads"
                multiple
                onChange={handleFileEvent}
                style={{ display: "none" }}
                disabled={fileLimit}
              />
              <Label htmlFor="uploads">
                <ContentLabel>
                  Attach your Resume/CV<Asterisk>*</Asterisk>
                </ContentLabel>
                <Input />
              </Label>
              <Label>
                <ContentLabel>
                  Full Name<Asterisk>*</Asterisk>
                </ContentLabel>
                <Input />
              </Label>
              <Label>
                <ContentLabel>
                  Email<Asterisk>*</Asterisk>
                </ContentLabel>
                <Input />
              </Label>
              <Label>
                <ContentLabel>
                  Phone<Asterisk>*</Asterisk>
                </ContentLabel>
                <Input />
              </Label>
              <Label>
                <ContentLabel>
                  Current Company<Asterisk>*</Asterisk>
                </ContentLabel>
                <Input />
              </Label>
            </Form>
            <SubTitle className="in-form">Links</SubTitle>
            <Form>
              <Label>
                Linkedin URL
                <Input />
              </Label>
            </Form>
            <SubTitle className="in-form">Aditional Information</SubTitle>
            <TextArea placeholder="Add a cover letter or anything else you want to share"></TextArea>
          </Content>
        </Container>
      </Background>
    </>
  );
};

export default FormVacancyDeveloper;
const NavbarSpace = styled.div`
  height: 20rem;
  background-color: ${(props) =>
    props.scroll ? "transparent" : "rgba(0, 0, 0, 0.7)"};
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
`;
const Label = styled.label`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const Input = styled.input`
  width: 60%;
  border: 1px solid gainsboro;
  padding: 1rem;
  &:focus {
    border: 1px solid transparent;
    outline: 0;
    box-shadow: 0 0 0 1px rgba(14, 190, 239, 0.37);
  }
`;
const Content = styled.div`
  margin: 0 auto;
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 26rem;
  width: 100%;
  margin-bottom: 6rem;
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  img {
    width: 6rem;
  }
`;
const SubTitle = styled.h6`
  font-size: 2.2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  word-spacing: 1rem;
  letter-spacing: 0.4rem;
  color: rgba(39, 170, 225, 1);
  @media (min-width: 768px) {
    font-size: 1.8rem;
  }
  &.in-form {
    color: #222;
    word-spacing: normal;
  }
`;

const Title = styled.h2`
font-size: 4rem;
font-weight bold;
text-transform: capitalize;
`;

const TextArea = styled.textarea`
  border: 1px solid gainsboro;
  padding: 1rem;
  min-height: 15vh;
  &:focus {
    border: 1px solid transparent;
    outline: 0;
    box-shadow: 0 0 0 1px rgba(14, 190, 239, 0.37);
  }
`;
const Asterisk = styled.span`
  font-size: 12px;
  color: rgba(39, 170, 225, 1);
  font-weight: bold;
`;
const ContentLabel = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
`;

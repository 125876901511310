import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const JobPositionsCell = ({
  id,
  position,
  ubication,
  thickLine,
  link = "#",
  setShowPosition,
  setRawHtml,
  html,
  setId,
}) => {
  const handleOnClick = () => {
    setRawHtml(html);
    setShowPosition(true);
    setId(id);
  };

  return (
    <FlexContainer thickLine={thickLine} to={link} onClick={handleOnClick}>
      <Id>{id}</Id>
      <Position>{position}</Position>
      <Ubication>{ubication}</Ubication>
    </FlexContainer>
  );
};

export default JobPositionsCell;

const FlexContainer = styled(Link)`
  display: flex;
  border-top: ${(props) =>
    props.thickLine ? "2px solid #EEEEEE" : "1px solid #EEEEEE"};
  width: 100%;
  align-items: center;
  color: #222;
  text-decoration: none;
  font-size: 2.5rem;
  padding: 1.75rem 0;
  @media (min-width: 768px) {
    font-size: 2rem;
    padding: 1rem 0;
  }
`;

const Id = styled.div`
  width: 15%;
`;
const Position = styled.div`
  width: 63%;
  color: rgba(9, 186, 239, 0.7);
`;
const Ubication = styled.div`
  width: 22%;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "../Generic-Components/GenericStyledComponents";
import spinner from "../../assets/logos/tube-spinner.svg";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/MakeAQuote.css";
import { validateEmail } from "../../lib/helpers";

const MakeAQuote = () => {
  const initialState = {
    name: "",
    email: "",
    phone: "",
    companyWebsite: "",
    message: "",
  };
  const [form, setForm] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errorMail, setErrorMail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [phoneLength, setPhoneLength] = useState(0);

  const messageSucceded = () =>
    toast.success("Message successfully sent, we will contact you soon", {
      position: "top-center",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const messageError = () =>
    toast.error("An error ocurred, please try later", {
      position: "top-center",
      autoClose: 7000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const fieldsMissed = (message) =>
    toast.warn(message, {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const isWebSite =
    !form.companyWebsite.trim().includes(" ") &&
    (form.companyWebsite.includes("http") ||
      form.companyWebsite.includes("www") ||
      form.companyWebsite.includes(".com") ||
      form.companyWebsite.includes(".gob") ||
      form.companyWebsite.includes(".edu") ||
      form.companyWebsite.includes(".net") ||
      form.companyWebsite.includes(".org") ||
      form.companyWebsite.includes(".ca") ||
      form.companyWebsite.includes(".mil"));
  const onHandleChage = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();

    if (!form.companyWebsite || !form.email || !form.name || !form.phone)
      return fieldsMissed("Please fill all the fields on the form");
    if (
      (!phoneLength || phoneLength != form.phone.length) &&
      !validateEmail(form.email)
    ) {
      setErrorMail(true);
      setErrorPhone(true);
      return fieldsMissed("Please add a valid email, and phone number");
    }
    if (!validateEmail(form.email)) {
      setErrorMail(true);
      return fieldsMissed("Please add a valid email");
    } else setErrorMail(false);
    if (!phoneLength || phoneLength != form.phone.length) {
      setErrorPhone(true);
      return fieldsMissed("Please complete your phone number");
    } else {
      setErrorPhone(false);
    }
    let body;
    if (isWebSite) {
      body = { ...form, website: form.companyWebsite };
    } else {
      body = { ...form, company: form.companyWebsite };
    }
    delete body.companyWebsite;
    setLoading(true);
    try {
      axios
        .post("http://localhost:4000/messages", body)
        .then((res) => {
          setLoading(false);
          messageSucceded();
          setForm(initialState);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          messageError();
        });
    } catch (err) {
      console.log(err);
      messageError();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (validateEmail(form.email)) setErrorMail(false);
  }, [form.email]);
  useEffect(() => {
    if (phoneLength == form.phone.length) setErrorPhone(false);
  }, [form.phone]);

  return (
    <>
      <ToastContainer autoClose={5000} />
      <Background id="start-with-us">
        <Container>
          <HeaderContainer>
            <SubTitle>Contact Us</SubTitle>
            <Title>Feel Free Contact </Title>
            <Title>Us Now</Title>
          </HeaderContainer>
          <Form onSubmit={onHandleSubmit}>
            <ContainerGroup>
              <Input
                placeholder="Name"
                name="name"
                value={form.name}
                onChange={onHandleChage}
              />
              <Input
                placeholder="Email"
                className={errorMail ? "error" : ""}
                name="email"
                value={form.email}
                onChange={onHandleChage}
              />
            </ContainerGroup>
            <ContainerGroup>
              <PhoneInput
                country={"us"}
                value={form.phone}
                onChange={(phone) => setForm({ ...form, phone })}
                isValid={(inputNumber, country, countries) => {
                  setPhoneLength(country.format.split(".").length - 1);
                  return !errorPhone;
                }}
              />
              <Input
                placeholder="Website/Company"
                name="companyWebsite"
                value={form.companyWebsite}
                onChange={onHandleChage}
              />
            </ContainerGroup>
            <ContainerGroup>
              <TextTarea
                placeholder="Your Message"
                name="message"
                value={form.message}
                onChange={onHandleChage}
              />
            </ContainerGroup>
            <Button type="submit">
              {loading ? (
                <img src={spinner} alt="spinner" width={35} />
              ) : (
                "Send Message"
              )}
            </Button>
          </Form>
        </Container>
      </Background>
    </>
  );
};

export default MakeAQuote;

const Background = styled.div`
  background-color: #eff2f7;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10rem;
`;

const SubTitle = styled.h6`
  font-size: 2.2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  word-spacing: 1rem;
  letter-spacing: 0.4rem;
  color: rgba(39, 170, 225, 1);
  @media (min-width: 768px) {
    font-size: 1.8rem;
  }
`;

const Title = styled.h2`
font-size: 4rem;
font-weight bold;
text-transform: capitalize;
`;

const ContainerGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Input = styled.input`
  margin: 2rem 0;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 16.5px 14px 16.5px 14px;
  font-size: 20px;
  color: gray;
  font-family: bold;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  border: 1px solid #cacaca;
  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &::placeholder {
    color: gainsboro;
  }
  &.error {
    border: 1px solid #f44336;
  }
  width: 300px;
  @media (min-width: 768px) {
    width: calc(50% - 4rem);
  }
`;
const TextTarea = styled.textarea`
  width: 300px;
  height: 20vh;
  margin: 2rem 0;
  padding: 16.5px 14px 16.5px 14px;
  border-radius: 5px;
  background-color: #ffffff;
  color: gray;
  font-family: bold;
  font-size: 20px;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  border: 1px solid #cacaca;
  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  &::placeholder {
    color: gainsboro;
  }
  @media (min-width: 768px) {
    width: 100%;
  }
`;
const Button = styled.button`
  cursor: pointer;
  width: 300px;
  transition: 0.3s ease-in;
  text-decoration: none;
  line-height: initial !important;
  border-radius: 8px;
  font-size: 3rem;
  font-weight: 500;
  padding: 13px 25px;
  text-transform: none;
  border: 1px solid #27aae1;
  margin: 4rem auto 0 auto;
  display: block;
  background-color: #27aae1;
  color: #ffffff;
  &:hover {
    opacity: 0.7;
    transition: 0.3s ease-in;
  }
  @media (min-width: 768px) {
    font-size: 2rem;
    width: initial;
  }
`;
const Form = styled.form`
  margin-top: 6rem;
  padding-bottom: 6rem;
`;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPositions } from "../../redux/positionsSlice";
import styled from "styled-components";
import JobPositionsCell from "./JobPositionsCell";
import JobsSearch from "./JobsSearch";
import Pagination from "./Pagination";
import {
  Container,
  Section,
  Title,
  SubTitle,
  Blue,
} from "../../components/Generic-Components/GenericStyledComponents";
import ModalPositions from "../../components/Modal/ModalPositions";

const JobPositionsTable = ({ setShowPosition, showPosition }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPositions());
  }, [dispatch]);

  const positions = useSelector((state) => state.positions.positions);

  console.log(positions);
  const [page, setPage] = useState(1);
  const [filteredArr, setFilteredArr] = useState(positions);
  const [rawHtml, setRawHtml] = useState("");
  const [id, setId] = useState("");
  const resultsPerPage = 10;
  const calcPages = filteredArr.length / resultsPerPage;
  const numberOfPages =
    calcPages % 1 == 0 ? calcPages : Math.floor(calcPages) + 1;
  const currentArray =
    filteredArr.length < resultsPerPage
      ? filteredArr
      : filteredArr.slice(resultsPerPage * (page - 1), resultsPerPage * page);
  useEffect(() => {
    setFilteredArr(positions);
  }, [positions]);

  return (
    <Section>
      <Container>
        <HeaderContainer>
          <SubTitle>Apply now !</SubTitle>
          <Title>
            Take a Look <br />
            to Our <Blue>Open Positions</Blue>
          </Title>
        </HeaderContainer>
        <JobsSearch
          setFilteredArr={setFilteredArr}
          data={positions}
          setPage={setPage}
        />
        <FlexContainer>
          <Id>ID</Id>
          <Position>Req Title</Position>
          <Ubication>Location</Ubication>
        </FlexContainer>
        <ModalPositions
          setShowPosition={setShowPosition}
          showPosition={showPosition}
          rawHtml={rawHtml}
          id={id}
        />

        {currentArray.map((p, i) => (
          <JobPositionsCell
            position={p.title}
            ubication={p.location}
            id={p.id}
            html={p.html}
            thickLine={i % 2 == 0 ? false : true}
            setShowPosition={setShowPosition}
            setRawHtml={setRawHtml}
            key={p.id}
            setId={setId}
          />
        ))}
        {positions.length == 0 && (
          <NoPositionsContainer>
            <NoPositionsMessage>
              No current position was found
            </NoPositionsMessage>
          </NoPositionsContainer>
        )}
        <Pagination pagesNumber={numberOfPages} page={page} setPage={setPage} />
      </Container>
    </Section>
  );
};

export default JobPositionsTable;

const RelativeEl = styled.div`
  position: relative;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  border-top: 2px solid #eeeeee;
  width: 100%;
  font-size: 2.5rem;
  padding: 1.75rem 0;
  @media (min-width: 768px) {
    font-size: 2rem;
    padding: 1rem 0;
  }
`;

const Id = styled.div`
  width: 15%;
`;
const Position = styled.div`
  width: 63%;
`;
const Ubication = styled.div`
  width: 22%;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 6rem 0;
  gap: 2rem;
`;
const NoPositionsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 30vh;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #eeeeee;
`;
const NoPositionsMessage = styled.p`
  font-size: 2rem;
`;

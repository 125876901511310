import React from 'react'
import styled from 'styled-components'
import { CgChevronDoubleLeft } from 'react-icons/cg'
import { CgChevronDoubleRight } from 'react-icons/cg'

const Pagination = ({pagesNumber=3, setPage, page}) => {

const arr = new Array(pagesNumber);

for (let index = 0; index < pagesNumber; index++) {
    
    arr[index]=<SquareButton onClick={()=>setPage(index+1)} selected={page==index+1?true:false}>{index+1}</SquareButton>
}


  return (

    <FlexContainer>
    {!pagesNumber||pagesNumber==1 ?'':(<>
      <SquareButton onClick={()=>setPage(page==1?1:page-1)}><CgChevronDoubleLeft/></SquareButton>
      {arr}
      <SquareButton onClick={()=>setPage(page==pagesNumber?pagesNumber:page+1)}><CgChevronDoubleRight/></SquareButton>
    </>
    )}
    </FlexContainer>
  )
}

export default Pagination





const SquareButton = styled.div`
    height: 25px;
    cursor: pointer;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #EEEEEE;
    font-size: 12px;
    background-color: ${props=>props.selected?'rgba(9, 186, 239, 0.7)':'#ffffff'};
    color:${props=>props.selected?'#ffffff':'rgba(9, 186, 239, 0.7)'};
`

const FlexContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 6rem;

`
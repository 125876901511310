import React from "react";
import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Home from "./pages/Home";
import DataEngineering from "./pages/DataEngineering";
import AIntelligence from "./pages/AIntelligence";
import CloudServices from "./pages/CloudServices";
import Company from "./pages/Company";
import Careers from "./pages/Careers";
import ContactUs from "./pages/ContactUs";
import Navigation from "./components/Navigation/Navigation";
import Footer from "./components/Footer/Footer";
import Outsourcing from "./pages/Outsourcing";
import Services from "./pages/Services";
import WebMobileDev from "./pages/WebMobileDev";
import APIDevelopment from "./pages/APIDevelopment";
import DataAnalytics from "./pages/DataAnalytics";
import DataConsulting from "./pages/DataConsulting";
import DatabaseDev from "./pages/DatabaseDev";
import Partners from "./pages/Partners";
import PrivacyAndLegal from "./pages/PrivacyAndLegal";
import "./index.css";
import FormVacancyDeveloper from "./pages/FormVacancyDeveloper";

const App = () => {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/artificial-intelligence" element={<AIntelligence />} />
        <Route path="/data-engineering" element={<DataEngineering />} />
        <Route path="/data-analytics" element={<DataAnalytics />} />
        <Route path="/data-consulting" element={<DataConsulting />} />
        <Route path="/database-development" element={<DatabaseDev />} />
        <Route path="/cloud-services" element={<CloudServices />} />
        <Route path="/recruitment" element={<Outsourcing />} />
        <Route path="/company" element={<Company />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/our-services" element={<Services />} />
        <Route path="/web-mobile-development" element={<WebMobileDev />} />
        <Route path="/api-development" element={<APIDevelopment />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/privacy-and-legal" element={<PrivacyAndLegal />} />
        <Route path="/:id/apply" element={<FormVacancyDeveloper />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
